@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

body {
  margin: 0;
  font-family: 'Rubik';
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(./assets/bg2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-size: 1.4rem;
}

.Minter {
  max-width: 80vh;
  padding: 0 10px;
  margin: 0 auto;
  text-align: left;

}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 100%;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}

button {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #254cdd;
  color: #254cdd;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
}

#title {
  padding-top: 32px;
}

#status {
  color:red;
}

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

#walletButton {
  text-align: right;
  float:right;
  margin-bottom: 10px;
  line-height: 16px;
}

.alignCenter{
  text-align: center; 
}

.labelWrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 280px;
  margin: auto;
}
.labelWrap .label{
  font-size: 1.2rem;
  padding: 0 5px;
  border-bottom: solid 2px rgba(214, 214, 215, 0.8);
}
.labelWrap .value{
  font-size: 1.4rem;
  font-weight: 600;
  color: #b8fbfe;
}
.imgLogo{
  margin-top: 5vmin;
  width: 30vmin;
  max-width: 256px;
  border-radius: 50%;
}
#mintButton {
  margin-top: 10px;
  background-color: #254cdd;
  color:white;
}

.pdf{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px;
}
.pdf a{
  
  color: aliceblue;
}